@mixin border-radius($px) {
    -webkit-border-radius: $px !important;
       -moz-border-radius: $px !important;
            border-radius: $px !important;
}

@mixin flex {
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

@mixin media-sm { @media (max-width: sizing($screen, sm)) { @content; } }
@mixin media-md { @media (max-width: sizing($screen, md)) { @content; } }
@mixin media-lg { @media (max-width: sizing($screen, lg)) { @content; } }
@mixin media-xl { @media (max-width: sizing($screen, xl)) { @content; } }