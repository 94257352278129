@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~normalize.css";
@import "styles/sizings.scss";
@import "styles/utils.scss";
@import "styles/colors.scss";

* {
    font-family: 'Varela Round', sans-serif;
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
    color: #222;

    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

button,
select,
input,
option,
a,
::ng-deep input,
::ng-deep select,
::ng-deep option,
::ng-deep button {
    font-family: "Varela Round", sans-serif;
    outline: none;
    font-size: sizing($font, default, sm) !important;
}

html,
body {
    width: 100%;
    height: 100%;
}

a {
    text-decoration: none;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-top: 70px;
    padding-bottom: 70px;
}

.cdk-overlay-dark-backdrop {
    background: rgba(128, 128, 128, 0.5);
}

.mdc-dialog__title {
    @include flex();

    align-items: center;
    margin: 0px;
    padding: 0px 24px !important;
    border-bottom: 1px solid #EFEFEF;
    font-weight: bold !important;
    font-size: 18px !important;
    letter-spacing: -0.5px !important;
    color: #222222 !important;
    height: 64px !important;
}

.mdc-dialog__title::before {
    display: none !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    @include border-radius(8px);

    box-shadow: #CCC 0px 7px 29px 0px !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
    padding-top: 24px !important;
}

.mat-mdc-dialog-actions {
    @include flex();

    justify-content: space-between !important;
    height: 64px !important;
    padding: 0px 24px !important;
    border-top: 1px solid #CCC !important;
}

.error {
    margin-top: 4px;
    color: #FF4444;
    text-align: left;
    font-size: sizing($font, default, xs);
    line-height: sizing($font, lineHeight, xs);
}

.section {
    @include flex();

    flex-direction: column;
    align-items: center;

    .content {
        width: 100%;
        max-width: 1140px;
        padding: 0px 24px;

        .page-title {
            @include flex();

            justify-content: space-between;
            align-items: center;
            border-bottom: 2px solid #EFEFEF;

            h1 {
                font-size: sizing($font, default, md);
                line-height: 48px;
                color: #AAAAAA;
                letter-spacing: 0.5px;
            }

            button {
                @include border-radius(24px);
                @include flex();

                height: 48px;
                line-height: 48px;
                border: none;
                color: #FFF;
                padding: 0px 24px 0px 16px;
                cursor: pointer;
                font-size: sizing($font, default, sm);
                font-weight: bold;
                align-items: center;
                letter-spacing: 0.5px;
                background: $green;

                mat-icon {
                    color: #FFF;
                    margin-right: 6px;
                }
            }

            .button-container {
                @include flex();

                button {
                    margin-left: 24px;
                }
            }
        }

        .form {
            h2 {
                padding: 12px 0px;
                font-size: sizing($font, default, md);
                line-height: sizing($font, lineHeight, md);
                letter-spacing: 0.5px;
            }

            fieldset {
                @include flex();

                flex-direction: column;
                border: none;
                padding: 0;
                margin: 12px 0px 0px 0px;

                label {
                    font-size: sizing($font, default, xs);
                    line-height: sizing($font, lineHeight, xs);
                    margin: 12px 0px 4px 0px;
                    font-weight: bold;
                    letter-spacing: 0.3px;
                }

                input[type=text], input[type=number] {
                    @include border-radius(6px);

                    height: 36px;
                    line-height: 36px;
                    padding: 0px 8px;
                    border: 1px solid #DDDDDD;
                }

                select {
                    @include border-radius(6px);

                    height: 36px;
                    line-height: 36px;
                    padding: 0px 8px;
                    background: #FFF;
                    border: 1px solid #DDDDDD;
                    font-weight: bold;
                }

                textarea {
                    @include border-radius(6px);

                    height: 256px;
                    padding: 8px;
                    border: 1px solid #DDDDDD;
                    resize: none;
                }

                .input-test-link {
                    @include flex();

                    align-items: center;
                    justify-content: space-between;
                    height: 36px;

                    input {
                        width: 100%;
                        margin-right: 24px;
                    }
                }
            }

            .button-container {
                margin-top: 24px;
            }
        }
    }
}

.desktop {
    display: block !important;
}

.mobile {
    display: none !important;
}

.disabled {
    color: #AAA !important;

    &:focus {
        outline: none !important;
    }
}

.display-none {
    display: none;
}

.not-found-container {
    margin-top: 24px;
    font-size: sizing($font, default, sm);
    line-height: sizing($font, lineHeight, sm);
}

@import "./classes/table.scss";
@import "./classes/button.scss";

@include media-sm {
    .desktop {
        display: none !important;
    }

    .mobile {
        display: block !important;
    }

    .cdk-overlay-pane {
        width: 100% !important;
        max-width: 100% !important;
        margin: 0px 24px !important;
    }
}
