.btn {
    @include border-radius(6px);
    @include flex();

    align-items: center;
    justify-content: center;
    height: 45px;
    line-height: 45px;
    border: none;
    cursor: pointer;
    color: #FFF;
    padding: 0px 24px;
    font-size: sizing($font, default, sm);
    background: #222222;
    text-align: center;

    mat-spinner {
        circle {
            stroke: #FFF !important;
        }
    }
}