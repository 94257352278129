.table-container {
    @include border-radius(24px);

    padding: 12px 24px;
    background: #E9F3F3;
}

.table {
    width: 100%;
    border-collapse: collapse;

    td, th {
        overflow: hidden;
        padding: 0px 8px;
        font-size: 15px;
        align-items: center;
    }

    tr {
        cursor: pointer;
        height: 36px;
        border-bottom: 1px solid #EEE;

        &:last-child {
            border: none;
        }
    }
}

.table tr td {
    color: #222;
}

.table td span {
    color: #AAA;
}

.table td .mat-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 34px;
}

.table td .mat-button-wrapper circle {
    stroke: #222;
}

.table td .mat-button-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 34px;
}

.table tr th:last-child div,
.table tr td:last-child,
.table tr th:nth-last-child(2) div,
.table tr td:nth-last-child(2) {
    text-align: right;
    justify-content: right;
}

.table .active mat-icon {
    color: #7C2AE8 !important;
}

.table tr th div {
    display: flex;
    align-items: center;
    line-height: 24px;
    text-align: left;
    font-size: 16px;
    color: #AAA;
}
  
.table tr th div mat-icon {
    color: #AAA;
}

/******************************************************************************
 * STATUS                                                                     *
 ******************************************************************************/

.table__status {
    @include border-radius(5px);

    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px 8px;
    width: auto;
    font-weight: bold;
    font-size: 14px !important;
    color: #222 !important;
    height: 20px;
    line-height: 20px;
}

.table__status--draft {
    @extend .table__status;

    background-color: #d7ca7d;
}

.table__status--enabled,
.table__status--executed {
    @extend .table__status;

    background-color: $green;
}

.table__status--disabled {
    @extend .table__status;

    background-color: $red;
}

.table__status--pending {
    @extend .table__status;

    background-color: $orange;
}
